<template>
  <v-main class="login">
    <div class="px-3 mt-10">
      <p class="body-2 mb-10">入力されたメールアドレスに、6桁の認証コードを記載した仮登録完了メールを送信いたします。</p>
      <ValidationObserver ref="observer">
        <v-row class="mb-4">
          <v-col cols="12" sm="6" class="pb-0">
            <p class="subtitle-2 font-weight-black ma-0">メールアドレス</p>
            <ValidationProvider v-slot="{ errors }" name="メールアドレス" rules="required|email|max:254">
            <v-text-field v-model="mail" label single-line outlined dense :error-messages="errors" required  @change="inputEmail"></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
      </ValidationObserver>

      <div class="text-center">
        <v-btn rounded class="maincolor-bg default_button mb-5" @click="register_email()">メールアドレス認証</v-btn>
      </div>

      <v-divider class="my-5"></v-divider>

      <v-row class="caption">
        <v-col cols="6" class="text-right">
        </v-col>
        <v-col cols="6" class="text-right">
          <router-link to="login">既にアカウントがある場合</router-link>
        </v-col>
      </v-row>

      <div class="text-center caption pt-10">
        <p class="">
          本アプリをご利用の際には、
          <router-link to="/info/rule">利用規約</router-link>
          をご確認ください。
        </p>
        <p class="caption" v-if="init.inquiry_mail">
          <v-icon>far fa-envelope</v-icon>
          <a :href="'mailto:'+init.inquiry_mail">お問い合わせ</a>
        </p>
      </div>
    </div>

    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import { required, email, max, min } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
setInteractionMode('eager')
extend('required', {
  ...required,
  message: '{_field_} は必須項目です。',
})

extend('email', {
  ...email,
  message: '正しいメールアドレス形式ではありません',
})

extend('max', {
  ...max,
  message: '{length}文字以内で入力してください。',
})

extend('min', {
  ...min,
  message: '{length}文字以上で入力してください。',
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      mail: null,

      init: this.storageGet('*'),
      loading: false,
      show: false,
    };
  },
  async created () {
  },
  mounted () {
    const json = this.storageSessionGet('*');
    if( !json ) {
      this.storageSessionSave(JSON.stringify({}));
    } else {
      const email = this.storageSessionGet("input_email");
      if(email) {
        this.mail = email;
      }
    }
  },
  methods: {
    register_email: function(){
      this.$refs.observer.validate().then(async result => {
        if (!result) {
            return;
        }
        try {
          //loadingを表示
          this.loading = true
          // 認証コード発行
          var req = {
            'mail': this.mail,
          }
          var res = await this.apiCallCustomerPF('/auth_code/issue', req);
          this.storageSave(JSON.stringify({
            'login_id' : this.mail,
            'auth_code': res.auth_code,
          }));

          this.$router.push({ path: 'check_authcode', query: { scene: 'create' } });
        } catch(e) {
            console.log(e);
            this.loading = false
            if (e.code==409) {
                this.callDialog('', '入力されたメールアドレスは既に登録されています。ログインしてください。', 7);
            } else {
                this.callDialog('認証コード発行エラー', '時間をおいてリトライしてください。', 3);
            }
        }
      });
    },
    async inputEmail(e){
      this.storageSessionSave(JSON.stringify({
        "input_email"  : e ,
      }));
    }
  }
};
</script>

<style scoped>
.fontsize12>>>label {
  font-size: 12px;
}

a {
  color: #333333;
}
</style>
